import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Button,
  Form,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
  Alert,
} from "reactstrap";
import axios from "axios";
import numeral, { options } from "numeral";
import FontAwesome from "react-fontawesome";
import { Animated } from "react-animated-css";
import moment from "moment";
import FormField from "./widgets/FormFields";
import {
  getRepayment,
  getmaxloan,
  getnewrepayment,
} from "./widgets/loanCalculator";
import { camelCase, allUpper } from "./widgets/string";
import FileBase64 from "./widgets/inputbase64";
import statesData from './_data/states.json'

class Apply extends Component {
  state = {
    submittedDone: false,
    currentStep: 0,
    registerError: "",
    loading: false,
    recruiters: [],
    recruiter: {},
    gender: "",
    file: "",
    fileBase64: "",
    paySlip1: "",
    paySlip1Base64: "",
    paySlip2: "",
    paySlip2Base64: "",
    customerId: "",
    terms: false,
    
    formdata: {
      firstname: {
        element: "input",
        value: "",
        config: {
          name: "fullname_input",
          type: "text",
          label: "Firstname",
          placeholder: "Enter your Firstname",
        },
        validation: {
          required: true,
          // words3: true,
        },
        // onblur will check validation after focus is remoed
        valid: true,
        touched: false,
        validationMessage: "",
      },
      middlename: {
        element: "input",
        value: "",
        config: {
          name: "fullname_input",
          type: "text",
          label: "Middlename",
          placeholder: "Enter your middlename",
        },
        validation: {
          required: false,
          // words3: true,
        },
        // onblur will check validation after focus is remoed
        valid: true,
        touched: false,
        validationMessage: "",
      },
      lastname: {
        element: "input",
        value: "",
        config: {
          name: "fullname_input",
          type: "text",
          label: "Lastname",
          placeholder: "Enter your Last Name",
        },
        validation: {
          required: true,
          //  words3: true,
        },
        // onblur will check validation after focus is remoed
        valid: true,
        touched: false,
        validationMessage: "",
      },
      phoneNumber: {
        element: "input",
        value: "",
        config: {
          name: "phoneNumber_input",
          type: "number",
          label: "Phone Number",
          placeholder: "0xxxxxxxxx",
        },
        validation: {
          required: true,
        },
        // onblur will check validation after focus is remoed
        valid: true,
        touched: false,
        validationMessage: "",
      },
      email: {
        element: "input",
        value: "",
        config: {
          name: "email_input",
          type: "email",
          label: "Email Address",
          placeholder: "Enter your Email Address",
        },
        validation: {
          email: true,
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      address: {
        element: "input",
        value: "",
        config: {
          name: "address_input",
          type: "text",
          label: "Address",
          placeholder: "Enter your Residential Address",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      state: {
        element: "select",
        value: "",
        config: {
          name: "state_input",
          type: "text",
          label: "State",
          placeholder: "Enter State",
          options: statesData,
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      city: {
        element: "input",
        value: "",
        config: {
          name: "city_input",
          type: "text",
          label: "City",
          placeholder: "Enter City",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      dob: {
        element: "input",
        value: "",
        config: {
          name: "dob_input",
          type: "date",
          label: "Date of Birth",
          placeholder: "Enter your Date of Birth",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      alternatePhoneNumber: {
        element: "input",
        value: "",
        config: {
          name: "alternatePhoneNumber_input",
          type: "number",
          label: "Alternate Phone Number",
          placeholder: "234xxxxxxxx",
          min: 0,
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      employer: {
        element: "input",
        value:"",
        readOnly :true,
        config: {
          name: "employer_input",
          type: "text",
          label: "Employer",
          placeholder: "Where do you Work?",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      dse: {
        element: "select",
        value: "",
        config: {
          name: "dse_input",
          type: "text",
          label: "Direct Sales Executive",
          options: [],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      recruiter: {
        element: "select",
        value: "",
        config: {
          name: "recruiter_input",
          type: "text",
          label: "Recruiter/HR Company",
          options: [
           
          ],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      jobTitle: {
        element: "input",
        value: "",
        config: {
          name: "jobTitle_input",
          type: "text",
          label: "Job Title",
          options: [],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      employeeNumber: {
        element: "input",
        value: "",
        config: {
          name: "employeeNumber_input",
          type: "text",
          label: "Employee/IPPIS Number",
          placeholder: "13xxxx",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      officeAddress: {
        element: "input",
        value: "",
        config: {
          name: "officeAddress_input",
          type: "text",
          label: "Office Address",
          placeholder: "Enter Office Address",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },

      dateOfEmployment: {
        element: "input",
        value: "",
        config: {
          name: "dateOfEmployment_input",
          type: "date",
          label: "Date of Employment",
          placeholder: "Enter Date of Employment",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      monthlySalary: {
        element: "input",
        value: "",
        config: {
          name: "monthlySalary_input",
          type: "number",
          label: "Monthly Salary(net)",
          placeholder: "Enter Monthly Salary",
          min: 0,
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      nextSalaryDate: {
        element: "input",
        value: "",
        config: {
          name: "nextSalaryDate_input",
          type: "date",
          label: "Next Salary Date",
          placeholder: "Enter your Next Payment Date",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      
      accountNumber: {
        element: "input",
        value: "",
        config: {
          name: "acn_input",
          type: "number",
          label: "Salary Account Number",
          placeholder: "Enter Account Number",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      bvn: {
        element: "input",
        value: "",
        config: {
          name: "bvn_input",
          type: "number",
          label: "Bank Verification Number",
          placeholder: "Enter bvn",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },

      title: {
        element: "select",
        value: "",
        config: {
          name: "title_input",
          type: "text",
          label: "Title",
          options: [
            {
              name: "",
              id: "",
            },
            {
              name: "Mr",
              id: "mr",
            },
            {
              name: "Mrs",
              id: "mrs",
            },
            {
              name: "Miss",
              id: "miss",
            },
            {
              name: "Dr",
              id: "dr",
            },
            {
              name: "Ms",
              id: "ms",
            },
          ],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      ban: {
        element: "select",
        value: "",
        config: {
          name: "bank_input",
          type: "text",
          label: "Salary Bank Name",
          options: [],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      loanTenor: {
        element: "select",
        value: "",
        config: {
          name: "lt_input",
          type: "text",
          disabled: false,
          label: "Loan Tenure",
          options: [
            {
              name: "",
              id: "",
            },
            {
              name: "3 Months",
              id: "3",
            },
            {
              name: "6 Months",
              id: "6",
            },
            {
              name: "9 Months",
              id: "9",
            },
            {
              name: "12 Months",
              id: "12",
            },
          ],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      repaymentOption: {
        element: "select",
        value: "",
        config: {
          name: "lt_input",
          type: "text",
          disabled: true,
          label: "Repayment Option",
          options: [
            {
              name: "",
              id: "",
            },
            {
              name: "Payroll Deduction",
              id: "payroll",
            },
            {
              name: "Direct Debit",
              id: "direct",
            },
            {
              name: "Card Payment",
              id: "card",
            },
          ],
        },
        validation: {
          required: false,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      amountDisbursable: {
        element: "input",
        value: "",
        config: {
          name: "la_input",
          type: "number",
          disabled: true,
          label: "Amount to be Disbursed",
          placeholder: "xxxxxx",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      loanAmt: {
        element: "input",
        value: "",
        config: {
          name: "la_input",
          type: "text",
          label: "Loan Amount",
          placeholder: "Enter Loan Amount",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      loanRepayment: {
        element: "input",
        value: "",
        config: {
          name: "lp_input",
          type: "text",
          label: "Loan Repayment",
          disabled: true,
        },
        validation: { required: true },
        valid: true,
      },
      repaymentDays: {
        element: "input",
        value: "",
        config: {
          name: "frd_input",
          type: "text",
          label: "First Repayment Date",
          disabled: true,
        },
        validation: { required: true },
        valid: true,
      },
      processingFee: {
        element: "input",
        value: "",
        config: {
          name: "pf_input",
          type: "number",
          label: "Processing Fee",
          disabled: true,
        },
        validation: { required: false },
        valid: true,
      },
      maximumloanAmt: {
        element: "input",
        value: "",
        config: {
          name: "maximumloanAmt_input",
          type: "text",
          label: "Maximum Loan Amount",
          disabled: true,
        },
        validation: {},
        valid: true,
      },
      
    },
  };

  componentDidMount() {
    const formdataCopy = {
      ...this.state.formdata,
    };
   
    const bankOptions = [
      {
        name: "",
        id: "",
      },
    ];
    const recruiterOptions = [
      {
        name: "",
        id: "",
      },
    ];
    const dseOptions = [
      {
        name: "",
        id: "",
      },
    ];

    
    // 33 for bank
    axios
      .get(process.env.REACT_APP_BASE_URL + "api/getBanks")
      .then(({ data }) => {
        //  console.log(data);
        data.map((bank) =>
          bankOptions.push({
            name: bank.name,
            id: bank.name.toUpperCase(),
          })
        );
      });
    axios
      .get(process.env.REACT_APP_BASE_URL + "api/getRecruiters")
      .then((resp) => {
        //  console.log(resp);
        let name;

        // console.log(resp.data);
        resp.data.map((recruiter) => {
          // console.log(recruiter);
          name = recruiter.name;

          recruiterOptions.push({
            name,
            id: recruiter._id,
          });
        });
        // console.log(recruiterOptions);
        formdataCopy.recruiter.config.options = recruiterOptions;
        this.setState({ recruiterOptions: recruiterOptions }); // Save recruiterOptions to state

        formdataCopy.ban.config.options = bankOptions;

        axios
          .get(process.env.REACT_APP_BASE_URL + "api/getDSE")
          .then((dseresp) => {
            //  console.log(resp);
            let dsename, dseId;

            // console.log(resp.data);
            dseresp.data.map((dse) => {
              // console.log(recruiter);
              dsename = dse.name;
              dseId = dse._id;

              dseOptions.push({
                name: dsename,
                id: dseId,
              });
            });
            // console.log(recruiterOptions);
            formdataCopy.dse.config.options = dseOptions;
            this.setState({
              formdata: formdataCopy,
              recruiters: resp.data,
            });
          })
          .catch((err) => {
            console.error(err.response);
          });
      })
      .catch((err) => {
        console.error(err.response);
      });
  }

  onRadioBtnClick(gender) {
    this.setState({
      gender,
    });
  }


  termsChange = () => {
    this.setState((prevState) => ({
      terms: !prevState.terms,
    }));
  };

  handleStaffId = (files) => {
    // const file = e.target.files[0];
    // console.log(file);

    if (files.file.size > "1000000") {
      return alert("File is too large, file size limit is 600kb");
    }
    this.setState({
      fileBase64: files.base64,
      file: files.file,
    });
  };

  getPayslip1 = (files) => {
    // console.log(files.base64);
    if (files.file.size > "1000000") {
      return alert("File is too large, file size limit is 600kb");
    }

    this.setState({
      paySlip1Base64: files.base64,
      paySlip1: files.file,
    });
  };

  getPaySlip2 = (files) => {
    //  console.log(files.base64);
    if (files.file.size > "1000000") {
      return alert("File is too large, file size limit is 600kb");
    }

    this.setState({
      paySlip2Base64: files.base64,
      paySlip2: files.file,
    });
  };

  getSlip = (e) => {
    const file = e.target.files[0];
    if (file.size > "1000000") {
      return alert("File is too large, file size limit is 600kb");
    }
    this.setState({
      payslip: file,
    });
  };

  validate = (element) => {
    let error = [true, ""];

    if (element.validation.email) {
      const valid = /\S+@\S+\.\S+/.test(element.value);
      const message = `${!valid ? "Must be a valid email" : ""}`;
      error = !valid ? [valid, message] : error;
    }

    if (element.validation.words3) {
      const valid = element.value.trim().split(" ").length >= 2;
      const message = `${!valid ? "Please input your full name" : ""}`;
      error = !valid ? [valid, message] : error;
    }
    if (element.validation.required) {
      const valid = element.value.trim() !== "";
      const message = `${!valid ? "This field is required" : ""}`;
      error = !valid ? [valid, message] : error;
    }
    return error;
  };

  updateForm = (element) => {
    let inputData = "";
    if (element.event.target.type == "text") {
      inputData = allUpper(element.event.target.value);
    } else {
      inputData = element.event.target.value;
    }
    const newFormdata = {
      ...this.state.formdata,
    };
    const newElement = {
      ...newFormdata[element.id],
    };

    if (inputData && element.id === "recruiter") {
      // find recruiter from array
      const recruiter = this.state.recruiters.filter(
        (rec) => String(rec._id) === inputData
      )[0];

      if (recruiter) {
        this.setState({ recruiter });
        newFormdata.maximumloanAmt.value = recruiter.maximum;
        newFormdata.processingFee.value = recruiter.fee;
        // if the date is feb, invalid date will be returned by moment
        newFormdata.repaymentDays.value = moment(recruiter.repaymentDays, "DD")
          .add(1, "M")
          .isValid()
          ? moment(recruiter.repaymentDays, "DD")
              .add(1, "M")
              .format("DD MMMM YYYY")
          : moment().add(1, "M").endOf("month").format(`DD MMMM YYYY`);

        const Tenoroptions = [
          {
            name: "",
            id: "",
          },
        ];
        for (let i = 3; i <= parseInt(recruiter.tenor, 10); i += 3) {
          if (i == 1) {
            Tenoroptions.push({
              name: `${i} Month`,
              id: i,
            });
          } else {
            Tenoroptions.push({
              name: `${i} Months`,
              id: i,
            });
          }

        }

        if (recruiter.manualTenors) {
          recruiter.manualTenors.split(",").map((tenor) => {
            if (tenor) {
              tenor = tenor.trim();

              if (!Number.isNaN(tenor)) {
                Tenoroptions.push({
                  name: `${tenor} Months`,
                  id: tenor,
                });
              }
            }
          });

          // sort in desc
          Tenoroptions.sort((a, b) => a.id - b.id);
        }

        newFormdata.loanTenor.config.options = Tenoroptions;
        
      }
    }

    if (element.id === "loanAmt") {
      newFormdata.loanRepayment.value = 0;
    }
    // add processing fee on submit

    if (element.id === "loanAmt") {
      // console.log(this.state.formdata.loanTenor.value);
      // console.log(this.state.formdata.maximumloanAmt.value);
      const name = 1000000;
      if (this.state.formdata.recruiter.value) {
        //  name = this.state.formdata.recruiter.value.split(',');
      }
      if (
        inputData >
        parseInt(
          numeral(`${this.state.formdata.maximumloanAmt.value}`).value(),
          10
        )
      ) {
        const maxreason = this.state.formdata.maximumloanAmt.value;
        return this.setState({
          loading: false,
          registerError: `You can only apply for the maximum of ${maxreason} for ${this.state.formdata.loanTenor.value} MONTHS`,
        });
      }
      //  const recruiterData = this.state.formdata.recruiter.value.split(',');
      if (this.state.formdata.loanTenor.value) {
        newFormdata.loanRepayment.value = numeral(
          getnewrepayment(
            inputData,
            this.state.recruiter.interest,
            this.state.formdata.loanTenor.value,
            false
          )
        ).format("0,0.00");
      }
    }
    if (element.id === "loanAmt" && this.state.recruiter && inputData) {
      const loanAmount = inputData * 1;
      //  inputData *= 1;
      // console.log(loanAmount > this.state.recruiter.fee * 1);
      if (loanAmount > this.state.recruiter.fee * 1) {
        if (this.state.recruiter.feeType.toLowerCase().trim() === "flat") {
          newFormdata.amountDisbursable.value =
            loanAmount * 1 - this.state.recruiter.fee * 1;
        } else if (
          this.state.recruiter.feeType.toLowerCase().trim() === "percentage"
        ) {
          newFormdata.amountDisbursable.value =
            loanAmount - (this.state.recruiter.fee / 100) * loanAmount;
        }
      }
     
    }

    let maxloan = 0;
    let maxreason = 0;
    let loanten = this.state.formdata.loanTenor.value;
    // let recruiterData = this.state.formdata.recruiter.value.split(',');
    let sal = this.state.formdata.monthlySalary.value;
    if (element.id === "loanTenor") {
      loanten = inputData;
    } else if (element.id === "recruiter") {
      // recruiterData = inputData.split(',');
    } else if (element.id === "monthlySalary") {
      sal = inputData;
    }
    //  console.log(loanten, sal, ' loan ten and salary');
    if (loanten && sal) {
      maxloan = getmaxloan(
        getRepayment(sal, this.state.recruiter.dti, 0),
        loanten,
        this.state.recruiter.interest
      );
      maxreason =
        this.state.recruiter.maximum < maxloan
          ? this.state.recruiter.maximum
          : maxloan;
    }
    // console.log(maxreason, 'maxreason');
    if (maxreason) {
      newFormdata.maximumloanAmt.value = Math.round(maxreason / 10000) * 10000;
    }

    if (element.id === "loanTenor") {
      console.log(this.state.formdata.loanAmt.value);

      if (this.state.formdata.loanAmt.value) {
        if (
          this.state.formdata.loanAmt.value >
          parseInt(
            numeral(`${this.state.formdata.maximumloanAmt.value}`).value(),
            10
          )
        ) {
          return this.setState({
            loading: false,
            registerError: `You can only apply for the maximum of ${this.state.formdata.maximumloanAmt.value} for ${this.state.formdata.loanTenor.value} MONTHS`,
          });
        }
        if (this.state.formdata.loanAmt.value) {
          newFormdata.loanRepayment.value = numeral(
            getnewrepayment(
              this.state.formdata.loanAmt.value,
              this.state.recruiter.interest,
              inputData,
              false
            )
          ).format("0,0.00");
        }
      }
    }
    newElement.value = inputData;
    if (element.blur) {
      //  console.log(element.id);
      const validData = this.validate(newElement);
      newElement.valid = validData[0];
      newElement.validationMessage = validData[1];
    }
    newElement.touched = element.blur;
    newFormdata[element.id] = newElement;

    this.setState({
      formdata: newFormdata,
    });
  };

  submitForm = (event) => {
    event.preventDefault();
    if (this.state.submittedDone) return;
    if (!this.state.terms) {
      return this.setState({
        registerError: "You have to accept the Terms and Condition",
      });
    }

    const dataToSubmit = {};
    let formIsValid = true;

    for (const key in this.state.formdata) {
      dataToSubmit[key] = this.state.formdata[key].value;
    }

    const repayment = numeral(`${dataToSubmit.loanRepayment}`).value() * 1;
    const loanAmount = numeral(`${dataToSubmit.loanAmt}`).value() * 1;
    // if (loanAmount && !repayment) {
    //   return this.calculateSche(dataToSubmit.phoneNumber, loanAmount, dataToSubmit.loanTenor, recruiterData[0].toLowerCase() != 'not applicable' ? null : (recruiterData[0].toLowerCase() == 'not applicable' && dataToSubmit.loanTenor == 1) ? 4 : 5);
    // }
    if (repayment < 1) {
      return this.setState({
        registerError: "Loan repayment is empty",
      });
    }

    let notValid = "";
    for (const key in this.state.formdata) {
      if (
        this.state.formdata[key].validation.required &&
        !this.state.formdata[key].value
      ) {
        notValid = key;
        // console.log(key);
        formIsValid = false;
        break;
      }
      // formIsValid = this.state.formdata[key].valid && this.state.formdata[key].validation.required && formIsValid;
      // console.log(key, formIsValid);
    }

    if (!this.state.file) {
      return this.setState({
        loading: false,
        registerError: "upload ID card",
      });
    }
    if (!this.state.gender) {
      return this.setState({
        loading: false,
        registerError: "select gender",
      });
    }
    if (!this.state.paySlip1Base64) {
      return this.setState({
        loading: false,
        registerError: "upload payslip 1",
      });
    }
    if (!this.state.paySlip2Base64) {
      return this.setState({
        loading: false,
        registerError: "upload payslip 2",
      });
    }

    if (loanAmount < this.state.recruiter.minimum) {
      return this.setState({
        loading: false,
        registerError: `You can only apply for a minimum of ${this.state.recruiter.minimum}`,
      });
    }
    if (formIsValid) {
      this.setState({
        loading: true,
      });
      const daydiff = moment(dataToSubmit.nextSalaryDate).diff(
        moment(),
        "days"
      );

      const ba = dataToSubmit.ban;

      const load = {
        ...dataToSubmit,
        // interest: recruiterData[0].toLowerCase() != 'not applicable' ? recruiterData[2] : (recruiterData[0].toLowerCase() == 'not applicable' && dataToSubmit.loanTenor == 1) ? 0.004 * (daydiff < 15 ? 15 : daydiff) : 0.05,
        recruiter: this.state.recruiter._id,
        loanAmount,
        repayment,
        gender: this.state.gender,
        remitaCustomerId: this.state.customerId,
        // genderType: this.state.gender == 31 ? 'Male' : 'Female',
        bank: ba,
        paySlip1: this.state.paySlip1Base64,
        paySlip2: this.state.paySlip2Base64,
        idCard: this.state.fileBase64,
      };

      console.log(load);
      console.log(this.state.phoneNumber)
      axios
        .post(process.env.REACT_APP_BASE_URL + "api/createClient", load)
        .then((response) => {
          const data = response.data.loanresp;
          if (data && data.err != 0) {
            this.setState({
              loading: false,
              registerError: data.err,
            });
            return;
          }

          this.setState({
            loading: false,
            registerError:
              "Loan Submitted Successfully for Processing. Changes will Reflect on your Dashboard within 24 Hours. You will be contacted soon",
            submittedDone: true,
          });
          
        })
        .catch((err) => {
          console.error(err.response);

          if (err.response && err.response.data.error) {
            this.setState({
              loading: false,
              registerError: err.response.data.error.defaultUserMessage,
            });
           
            return;
          }
          
          this.setState({
            loading: false,
            registerError: "An Error Occured",
          });
        });
    } else {
      this.setState({
        loading: false,
        registerError: `Please Fill all Required Fields Err:${notValid}`,
      });
    }

    // }
  };

  startApplication = async () => {
    try {
      if (
        this.state.formdata.phoneNumber.value.toString()[0] !== "0" ||
        this.state.formdata.phoneNumber.value.toString().length !== 11
      ) {
        return window.alert("please input a valid phone number");
      }

      const { data: found } = await axios.get(
        process.env.REACT_APP_BASE_URL +
          `api/findclient/${this.state.formdata.phoneNumber.value}`
      );
      if (found) {
        alert("Please apply from dashboard");
        this.props.history.push("/login");
      } else {
        this.setState({ currentStep: 1, loading: false });
        this.presetEmployerField()
      }
    } catch (error) {
      this.setState({
        loading: false,
        registerError: "An error occured while processing request",
      });
    }
  };

  presetEmployerField = () => {
    const recruiterId = this.state.formdata.recruiter.value;
    const rec = this.state.recruiterOptions.filter((option) => option.id === recruiterId);
    if (rec) {
      this.setState((prevState) => ({
        formdata: {
          ...prevState.formdata,
          employer: {
            ...prevState.formdata.employer,
            value: rec[0].name || "", // Ensure value is always a string
          },
        },
      }));
    }
  }

  validateInputFields = () => {
    const { bvn, accountNumber } = this.state.formdata;
  
    if (bvn.value.length !== 11) {
      this.setState({ registerError: "BVN field must be exactly 11 digits" });
      return;
    }
  
    if (accountNumber.value.length !== 10) {
      this.setState({ registerError: "Account number field must be exactly 10 digits" });
      return;
    }
  
    this.setState({
      registerError: "",
      currentStep: 2,
    });
  }
  

  render() {
    const bg = {
      background:
        "url(/assets/img/checkoff-application-bg.jpg) no-repeat center center fixed",
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      backgroundSize: "cover",
      // position:'absolute',
      height: "100%",
    };

    return (
      <Container fluid style={bg}>
        <Row>
          <Col xs="12" sm="8" className="mx-auto mb-5">
            <Row className="flex-column align-items-center my-5">
              <a href="/">
                <img src="/assets/img/logo.png" alt="" />
              </a>
            </Row>{" "}
            <Row className="flex-column align-items-center">
              <h3
                className="font-circularlight"
                style={{ marginBottom: "30px" }}
              >
                {" "}
                Loan Application Form
              </h3>{" "}
            </Row>{" "}
            <div className="top-list mb-4">
              <div
                id="personal-details"
                className={`${
                  this.state.currentStep > 0
                    ? "pactive"
                    : this.state.currentStep == 0 && "active"
                } list-item`}
                style={{ borderRadius: "4px 0 0 4px" }}
              >
                Request Details
              </div>
              <div
                id="personal-details"
                className={`${
                  this.state.currentStep > 1
                    ? "pactive"
                    : this.state.currentStep == 1 && "active"
                } list-item`}
                style={{ borderRadius: "4px 0 0 4px" }}
              >
                Personal Details
              </div>
              
              <div
                id="loan-details"
                className={`${
                  this.state.currentStep > 2
                    ? "pactive"
                    : this.state.currentStep == 2 && "active"
                } list-item`}
                style={{ borderRadius: "0 4px 4px 0" }}
              >
                Loan Details
              </div>
            </div>
            <Jumbotron
              className="ui-box-shadow-dark-light mb-3"
              style={{
                backgroundColor: "#fff",
              }}
            >
             {" "}
              <section className="ui-form-step row">
                <div className="container">
                  <Form onSubmit={this.submitForm}>
                    {/* Create account */}

                    {this.state.currentStep === 0 && (
                      <Animated
                        animationIn="fadeIn"
                        animationInDuration={400}
                        isVisible
                        className="ui-form-step-tab "
                      >
                        <div className="mrgt1">
                          
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <div className="row justify-content-center">
                              <Col md="3">
                                <FormField
                                  id="phoneNumber"
                                  formdata={this.state.formdata.phoneNumber}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </Col>{" "}
                              <div className="col-md-3">
                                <FormField
                                  id="recruiter"
                                  formdata={this.state.formdata.recruiter}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </div>
                              
                              <div className="col-md-12">
                                <Jumbotron style={{ padding: "2rem 1rem" }}>
                                  <b
                                    style={{
                                      fontStyle: "italic",
                                      fontSize: 14,
                                    }}
                                  >
                                    {" "}
                                    By clicking Start Application, I consent to
                                    Checkoff Credit obtaining information from
                                    relevant third parties as may be necessary,
                                    on my employment details, salary payment,
                                    loans and other related data, to make a
                                    decision on my loan application. I also
                                    consent to the loan amounts being deducted
                                    from my salary at source before credit to my
                                    account and any outstanding loans being
                                    recovered automatically from any other
                                    accounts linked to me in the case of default
                                  </b>
                                </Jumbotron>
                              </div>
                            </div>{" "}
                          </div>
                        </div>

                        <Col md="6" className="mx-auto mt-1 text-center">
                          <Alert
                            color="danger"
                            isOpen={this.state.registerError}
                            toggle={() => this.setState({ registerError: "" })}
                            fade={false}
                          >
                            {this.state.registerError}
                          </Alert>
                        </Col>

                        {this.state.loading ? (
                          <Col md="3" className="mx-auto mt-5 text-center">
                            <FontAwesome
                              name="spinner"
                              size="2x"
                              style={{
                                color: "#c82590",
                              }}
                              spin
                            />
                          </Col>
                        ) : (
                          <Button
                            className="  pull-right"
                            //   size="sm"
                            disabled={
                              !this.state.formdata.phoneNumber.value ||
                              !this.state.formdata.recruiter.value
                            }
                            style={{
                              backgroundColor: "#F46A29",
                              borderColor: "#F46A29",
                              //   marginTop: "60px"
                            }}
                            onClick={this.startApplication}
                          >
                            Start Application
                          </Button>
                        )}
                      </Animated>
                    )}

                    {this.state.currentStep === 1 && (
                      <Animated
                        animationIn="fadeIn"
                        animationInDuration={400}
                        isVisible
                        className="ui-form-step-tab "
                      >
                        <div className="mrgt1">
                          
                          <p className="brand-dark font-circular fs-16 mrgt1">
                            Your personal information is needed to apply for a
                            loan.{" "}
                          </p>{" "}
                        </div>

                        <div className="row">
                          <div className="col-md-12 col-lg-12">
                            <Row>
                              <Col md="4">
                                <FormField
                                  id="firstname"
                                  formdata={this.state.formdata.firstname}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </Col>{" "}
                              <Col md="4">
                                <FormField
                                  id="lastname"
                                  formdata={this.state.formdata.lastname}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </Col>{" "}
                              <Col md="4">
                                <FormField
                                  id="middlename"
                                  formdata={this.state.formdata.middlename}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </Col>{" "}
                            </Row>
                            <div className="row">
                              {" "}
                              <div className="col-md-4">
                                <FormGroup>
                                  <Label for="">Gender* </Label> <br />
                                  <ButtonGroup>
                                    <Button
                                      color="secondary"
                                      className="btnsecondary sel"
                                      onClick={() =>
                                        this.onRadioBtnClick("male")
                                      }
                                      active={this.state.gender === "male"}
                                    >
                                      {" "}
                                      Male{" "}
                                    </Button>{" "}
                                    <Button
                                      color="secondary"
                                      className="btnsecondary sel"
                                      onClick={() =>
                                        this.onRadioBtnClick("female")
                                      }
                                      active={this.state.gender === "female"}
                                    >
                                      {" "}
                                      Female{" "}
                                    </Button>{" "}
                                  </ButtonGroup>{" "}
                                </FormGroup>
                              </div>
                              <Col md="4">
                                <FormField
                                  id="title"
                                  formdata={this.state.formdata.title}
                                  change={(element) => this.updateForm(element)}
                                />
                              </Col>
                              <div className="col-md-4">
                                <FormField
                                  id="email"
                                  formdata={this.state.formdata.email}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </div>
                              <div className="col-md-4">
                                <FormField
                                  id="dob"
                                  formdata={this.state.formdata.dob}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </div>{" "}
                              <Col md="4">
                                <FormField
                                  id="address"
                                  formdata={this.state.formdata.address}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </Col>
                              <Col md="4">
                                <FormField
                                  id="city"
                                  formdata={this.state.formdata.city}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </Col>{" "}
                              <Col md="6">
                                <FormField
                                  id="state"
                                  formdata={this.state.formdata.state}
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </Col>{" "}
                              <div
                                className="col-md-6"
                                style={{
                                  padding: 0,
                                }}
                              >
                                <FormField
                                  id="alternatePhoneNumber"
                                  formdata={
                                    this.state.formdata.alternatePhoneNumber
                                  }
                                  change={(element) => this.updateForm(element)}
                                />{" "}
                              </div>{" "}
                            </div>
                          </div>
                        </div>

                       
                        <div className="mrgt1">
                         
                          <p className="brand-dark font-circular fs-16 mrgt1">
                            We would like to know about your employer.{" "}
                          </p>{" "}
                        </div>

                        <div className="row">
                          <div className="col-md-4">
                            <FormField
                              id="employer"
                              formdata={this.state.formdata.employer}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>
                          
                          <div className="col-md-4">
                            <FormField
                              id="dateOfEmployment"
                              formdata={this.state.formdata.dateOfEmployment}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>
                          <Col md="4">
                            <FormField
                              id="officeAddress"
                              formdata={this.state.formdata.officeAddress}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </Col>
                          <div className="col-md-4">
                            <FormField
                              id="nextSalaryDate"
                              formdata={this.state.formdata.nextSalaryDate}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>
                          <div className="col-md-4">
                            <FormField
                              id="monthlySalary"
                              formdata={this.state.formdata.monthlySalary}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>
                          <div className="col-md-4">
                            <FormField
                              id="jobTitle"
                              formdata={this.state.formdata.jobTitle}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>

                          <div className="col-md-3">
                            <FormField
                              id="employeeNumber"
                              formdata={this.state.formdata.employeeNumber}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>
                          <div className="col-md-3">
                            <FormField
                              id="bvn"
                              formdata={this.state.formdata.bvn}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>
                          <div className="col-md-3">
                            <FormField
                              id="ban"
                              formdata={this.state.formdata.ban}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>
                          <div className="col-md-3">
                            <FormField
                              id="accountNumber"
                              formdata={this.state.formdata.accountNumber}
                              change={(element) => this.updateForm(element)}
                            />{" "}
                          </div>{" "}
                          {" "}
                        </div>

                        <Alert
                              color="danger"
                              isOpen={this.state.registerError}
                              toggle={() =>
                                this.setState({ registerError: "" })
                              }
                              fade={false}
                            >
                              {this.state.registerError}
                        </Alert>
                        
                        <Button
                          className=" pull-right"
                          // size="sm"
                          style={{
                            backgroundColor: "#F46A29",
                            borderColor: "#F46A29",
                            //  marginTop: ""
                          }}
                          onClick={this.validateInputFields}
                        >
                          Proceed
                        </Button>
                       
                      </Animated>
                    )}
                    {/* Loan Details starts here */}
                    {this.state.currentStep === 2 && (
                      <Animated
                        animationIn="fadeIn"
                        animationInDuration={400}
                        isVisible
                        className="last-step "
                      >
                        <div className="ui-form-step-tab">
                          
                        </div>{" "}
                        {/* <!--Step 3 ends--> */}
                        {/* <!--Step 4--> */}{" "}
                        <div className="ui-form-step-tab">
                          <div className="mrgt1">
                            {/* <h3 className='font-circularlight brand-blue'> Loan Details </h3>{" "} */}
                            <p className="brand-dark font-circular fs-16 mrgt1">
                              How much do you need and for how many Months.{" "}
                            </p>{" "}
                          </div>

                          <div className="row">
                            {" "}
                            <Col md={6}>
                              <FormField
                                id="repaymentDays"
                                formdata={this.state.formdata.repaymentDays}
                                change={(element) => this.updateForm(element)}
                              />
                            </Col>
                            <Col md={6}>
                              <FormField
                                id="maximumloanAmt"
                                formdata={this.state.formdata.maximumloanAmt}
                                change={(element) => this.updateForm(element)}
                              />
                            </Col>
                            
                            <Col md={6}>
                              <FormField
                                id="loanTenor"
                                formdata={this.state.formdata.loanTenor}
                                change={(element) => this.updateForm(element)}
                              />{" "}
                            </Col>{" "}
                            <div className="col-md-6">
                              <FormField
                                id="loanAmt"
                                formdata={this.state.formdata.loanAmt}
                                change={(element) => this.updateForm(element)}
                              />
                            </div>
                            <Col md={4}>
                              <FormField
                                id="amountDisbursable"
                                formdata={this.state.formdata.amountDisbursable}
                                change={(element) => this.updateForm(element)}
                              />{" "}
                            </Col>
                            <div className="col-md-4">
                              <FormField
                                id="loanRepayment"
                                formdata={this.state.formdata.loanRepayment}
                                change={(element) => this.updateForm(element)}
                              />{" "}
                            </div>
                            <div className="col-md-4">
                              <FormField
                                id="dse"
                                formdata={this.state.formdata.dse}
                                change={(element) => this.updateForm(element)}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <Col md="4">
                              <FormGroup>
                                <Label for="exampleFile1">
                                  {" "}
                                  Official ID Card(.jpeg or.png) *{" "}
                                </Label>{" "}
                                
                                <FileBase64
                                  fileId="file1"
                                  multiple={false}
                                  labelName={this.state.file.name}
                                  onDone={this.handleStaffId}
                                />
                                
                              </FormGroup>{" "}
                            </Col>
                            <Col md="4">
                              <FormGroup>
                                <Label for="exampleFile">
                                  {" "}
                                  Payslip 1(.jpeg or.png) *{" "}
                                </Label>{" "}
                                <br />
                                <FileBase64
                                  multiple={false}
                                  labelName={this.state.paySlip1.name}
                                  onDone={this.getPayslip1}
                                />{" "}
                              </FormGroup>{" "}
                            </Col>

                            <Col md="4">
                              <FormGroup>
                                <Label for="exampleFile">
                                  {" "}
                                  Pay Slip 2(.jpeg or.png) *{" "}
                                </Label>{" "}
                                <br />
                                <FileBase64
                                  fileId="file3"
                                  multiple={false}
                                  labelName={this.state.paySlip2.name}
                                  onDone={this.getPaySlip2}
                                />
                                
                              </FormGroup>{" "}
                            </Col>
                          </div>

                          {this.state.formdata.recruiter.value.split(",")[0] ==
                            "Not Applicable" &&
                          this.state.formdata.loanTenor.value == 1 ? (
                            <div className="col-md-12">
                              <Jumbotron style={{ padding: "2rem 1rem" }}>
                                <h5
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  {" "}
                                  I agree that my loan repayment will be
                                  deducted from next salary.{" "}
                                </h5>
                              </Jumbotron>
                            </div>
                          ) : null}
                        </div>
                        <div className="ui-form-step-tab">
                          <div className="mrgt1">
                            
                            <p className="brand-dark font-circular fs-16 mrgt1">
                              Accept the terms and condition for the loan{" "}
                            </p>{" "}
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        onChange={this.termsChange}
                                        type="checkbox"
                                        checked={this.state.terms}
                                      />{" "}
                                      I accept{" "}
                                      <a
                                        style={{ color: "#F79461" }}
                                        href="https://res.cloudinary.com/dedukt/image/upload/v1709296966/Terms_and_conditions_revised_igvvca.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        terms and conditions
                                      </a>
                                    </Label>
                                  </FormGroup>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                        {/* Step 4 ends */}
                        <Row>
                          <Col className="my-3" md={12}>
                            {" "}
                            <Alert
                              color="danger"
                              isOpen={this.state.registerError}
                              toggle={() =>
                                this.setState({ registerError: "" })
                              }
                              fade={false}
                            >
                              {this.state.registerError}
                            </Alert>
                           {" "}
                            {this.state.loading ? (
                              <Col md="3" className="mx-auto mt-5 text-center">
                                <FontAwesome
                                  name="spinner"
                                  size="2x"
                                  style={{
                                    color: "#c82590",
                                  }}
                                  spin
                                />
                              </Col>
                            ) : (
                              <>
                                <Button
                                  className="btn "
                                  //  size="md"
                                  onClick={() => {
                                    this.setState({ currentStep: 1 });
                                  }}
                                >
                                  Back
                                </Button>
                                {!this.state.submittedDone && (
                                  <>
                                    <Button
                                      type="submit"
                                      className="pull-right btnprimary"
                                      //  size="lg"
                                      color="info"
                                    >
                                      {" "}
                                      {this.state.formdata.loanAmt.value &&
                                      !this.state.formdata.loanRepayment.value
                                        ? "Proceed"
                                        : "Get Loan"}{" "}
                                    </Button>
                                  </>
                                )}
                              </>
                            )}{" "}
                          </Col>{" "}
                        </Row>
                      </Animated>
                    )}
                    
                  </Form>
                </div>
              </section>
              {" "}
            </Jumbotron>{" "}
          </Col>{" "}
        </Row>{" "}
      </Container>
    );
  }
}

export default Apply;
